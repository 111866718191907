// extracted by mini-css-extract-plugin
export var arrow = "ClientRow-module--arrow--e61f9";
export var client = "ClientRow-module--client--b96e4";
export var clientContent = "ClientRow-module--clientContent--358c5";
export var clientContentContainer = "ClientRow-module--clientContentContainer--4e6a1";
export var clientDescription = "ClientRow-module--clientDescription--b4e2d";
export var clientHeader = "ClientRow-module--clientHeader--f5fdb";
export var clientItem = "ClientRow-module--clientItem--1ec50";
export var clientTags = "ClientRow-module--clientTags--7bcfc";
export var clientTitle = "ClientRow-module--clientTitle--50f86";
export var collapsed = "ClientRow-module--collapsed--c2cc8";
export var expanded = "ClientRow-module--expanded--205b0";
export var hidden = "ClientRow-module--hidden--0b2dd";
export var rotate = "ClientRow-module--rotate--a7007";