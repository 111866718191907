import React, { useState, useEffect, useCallback } from "react"
import Micromark from "../Micromark"
import ClientRow, { Client } from "./ClientRow/ClientRow"
import isIntersecting from "../../utils/isIntersecting"
import deurlify from "../../utils/deurlify"
import urlify from "../../utils/urlify"

import * as styles from "./ClientGrid.module.css"

interface Tag {
  text: string
  tagValues: string[]
}

interface ClientGridProps {
  clients: Client[]
  tags: Tag[]
}

function ClientGrid({ clients, tags }: ClientGridProps) {
  const paramTag =
    typeof window !== "undefined"
      ? deurlify(new URLSearchParams(window.location.search).get("tag"))
      : ""
  const [firstTag] = tags
  const [tagSelected, setTagSelected] = useState<Tag>(firstTag)
  const [visibleClientIds, setVisibleClientIds] = useState<string[]>(
    clients.map(client => client.id)
  )

  useEffect(() => {
    if (!paramTag) return
    const tagSelected = tags.find(tag => paramTag === tag.text) || firstTag
    setTagSelected(tagSelected)
  }, [paramTag])

  useEffect(() => {
    updateVisibleClients(tagSelected)
  }, [clients, tagSelected])

  function handleClick(textTag: string) {
    const tagSelected = tags.find(tag => textTag === tag.text) || firstTag
    updateVisibleClients(tagSelected)
    window.history.replaceState({}, "", `/work?tag=${urlify(textTag)}`)
  }

  const updateVisibleClients = (tag: Tag) => {
    if (tag.tagValues.includes("all")) {
      setVisibleClientIds(clients.map(client => client.id))
      return
    }
    const visibleIds = clients
      .filter(client => {
        return client.content.some(content =>
          isIntersecting(content.tags, tag.tagValues)
        )
      })
      .map(client => client.id)
    setVisibleClientIds(visibleIds)
  }

  const filterClientContentByTag = useCallback(
    (client: Client): Client => {
      const lowerCaseTag = tagSelected.text.toLowerCase().trim()
      const isVisible = visibleClientIds.includes(client.id)
      if (lowerCaseTag === "all" || !lowerCaseTag || !isVisible) return client
      const visibleContent = client.content.filter(item =>
        isIntersecting(item.tags, tagSelected.tagValues)
      )
      return { ...client, content: visibleContent }
    },
    [tagSelected, visibleClientIds]
  )

  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <ul className={styles.tags}>
          {tags.map(tag => (
            <li
              id={tag.text}
              key={tag.text}
              className={`${styles.tag} ${
                tagSelected.text === tag.text ? styles.active : ""
              }`}
            >
              <Micromark
                as="span"
                onClick={() => handleClick(tag.text)}
                markdown={tag.text}
              />
            </li>
          ))}
        </ul>
      </nav>
      {clients.map(client => (
        <ClientRow
          client={filterClientContentByTag(client)}
          isShown={visibleClientIds.includes(client.id)}
        />
      ))}
    </div>
  )
}

export default ClientGrid
