import Header from "econify/components/Header"
import Footer from "econify/components/Footer"
import React from "react"

import Work from "../routes/Work"
import * as styles from "../routes/Work/Work.module.css"

const WorkPage: React.FC = () => {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <Work />
      </div>
      <Footer />
    </>
  )
}

export default WorkPage
