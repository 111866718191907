import React, { useState } from "react"
import { withPrefix } from "gatsby"

import Micromark from "../../Micromark"

import * as styles from "./ClientRow.module.css"

interface Content {
  description: string
  tags: string[]
}

export interface Client {
  id: string
  client: string
  content: Content[]
}

interface ClientProps {
  client: Client
  isShown: boolean
}

interface ClientContentProps {
  client: Client
  isExpanded?: boolean
  onClick?: () => void
}

function ClientContent({ client, isExpanded }: ClientContentProps) {
  if (!client.content.length) return null

  const [firsClientContent, ...restClientContents] = client.content
  const contentClass = `${styles.clientContent} ${
    isExpanded ? styles.expanded : styles.collapsed
  }`

  return (
    <div className={styles.clientContentContainer}>
      <div className={styles.clientItem}>
        <Micromark
          className={styles.clientDescription}
          markdown={firsClientContent.description}
        />
        <Micromark
          className={styles.clientTags}
          markdown={firsClientContent.tags.join(", ")}
        />
      </div>
      <div className={contentClass}>
        {restClientContents.map((content, index) => (
          <div
            key={`${client.id}-content-${index + 1}`}
            className={styles.clientItem}
          >
            <Micromark
              className={styles.clientDescription}
              markdown={content.description}
            />
            <Micromark
              className={styles.clientTags}
              markdown={content.tags.join(", ")}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

function ClientRow({ client, isShown }: ClientProps) {
  const [expandedClientId, setExpandedClientId] = useState<string | null>(null)
  const isExpanded = expandedClientId === client.id
  const isExpandable = client.content.length > 1
  const props = isExpandable
    ? { role: "button", onClick: () => handleClientClick(client.id) }
    : {}

  function handleClientClick(clientId: string) {
    setExpandedClientId(expandedClientId === clientId ? null : clientId)
  }

  return (
    <div
      className={`${styles.client} ${isShown ? "" : styles.hidden}`}
      {...props}
    >
      <div className={styles.clientHeader}>
        <img
          alt="arrow icon"
          src={withPrefix("/images/arrow-right.svg")}
          className={`${styles.arrow} ${isExpanded ? styles.rotate : ""} ${
            isExpandable ? "" : styles.hidden
          }`}
          loading="lazy"
        />
        <Micromark className={styles.clientTitle} markdown={client.client} />
      </div>
      <ClientContent isExpanded={isExpanded} client={client} />
    </div>
  )
}

export default ClientRow
