import React from "react"

import clients from "econify/data/work/clients.json"
import tags from "econify/data/work/tags.json"

import ClientGrid from "../../components/ClientGrid"

import * as styles from "./Work.module.css"

const WorkPage: React.FC = () => {
  return (
    <>
      <div className={styles.section}>
        <h2>Work</h2>
        <hr />
        <ClientGrid clients={clients} tags={tags} />
      </div>
    </>
  )
}

export default WorkPage
